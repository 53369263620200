import { Controller } from "@hotwired/stimulus";
import * as React from "react";
import { createRoot } from "react-dom/client";
import SignatureCanvas from "react-signature-canvas";

export default class extends Controller {
  static targets = ["signature", "field"];

  connect() {
    const root = createRoot(this.signatureTarget);

    root.render(
      <SignatureCanvas
        canvasProps={{
          width: 400,
          height: 200,
          className:
            "border border-white border-opacity-50 shadow-inner max-w-full",
        }}
        ref={(ref) => {
          this.canvas = ref;
        }}
      />,
    );
  }

  submit(e) {
    if (this.canvas.isEmpty()) {
      alert(
        "Please sign in the box by drawing with your mouse or finger on touch screens",
      );
      e.preventDefault();
      e.stopPropagation();
    }

    let data = this.canvas.getTrimmedCanvas().toDataURL("image/png", 0.1);
    this.fieldTarget.value = data;
  }

  reset() {
    if (this.canvas) {
      this.canvas.clear();
    }
  }
}
