import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "years", "months"];

  connect() {
    let totalMonths = parseInt(this.fieldTarget.value);

    if (isNaN(totalMonths)) {
      this.fieldTarget.value = totalMonths = 0;
    }

    this.yearsTarget.value = Math.floor(totalMonths / 12);
    this.monthsTarget.value = totalMonths % 12;
  }

  calculate() {
    const years = parseInt(this.yearsTarget.value);
    const months = parseInt(this.monthsTarget.value);
    this.fieldTarget.value = years * 12 + months;
  }
}
