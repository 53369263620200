import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "destroy"];

  add(event) {
    event.preventDefault();
    event.currentTarget.insertAdjacentHTML(
      "beforebegin",
      this.templateTarget.innerHTML.replace(
        /__CHILD_INDEX__/g,
        new Date().getTime().toString(),
      ),
    );
  }

  remove(event) {
    event.preventDefault();
    const parentField = event.currentTarget.parentElement;
    const destroyField = parentField.querySelector(
      ':scope > input[name*="_destroy"]',
    );

    if (destroyField) {
      destroyField.value = "1";
      parentField.style.display = "none";

      // Remove "required" from any now hidden fields to prevent a Chrome error
      const inputs = parentField.querySelectorAll(
        ":scope input, :scope textarea",
      );
      inputs.forEach((el) => {
        el.removeAttribute("required");
      });
    }
  }
}
