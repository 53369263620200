import { Controller } from "@hotwired/stimulus";
import Api from "../common/api";

export default class extends Controller {
  static targets = ["dialog", "preview", "subject"];

  static values = {
    open: Boolean,
  };

  preview(e) {
    const templateId = e.target.getAttribute("data-template-id");

    this.loadContent(templateId, () => {
      this.dialogTarget.showModal();
    });
  }

  close() {
    this.dialogTarget.setAttribute("closing", "");

    Promise.all(
      this.dialogTarget.getAnimations().map((animation) => animation.finished),
    ).then(() => {
      this.dialogTarget.removeAttribute("closing");
      this.dialogTarget.close();
    });
  }

  loadContent(id, callback = () => {}) {
    const api = new Api();
    const formData = new FormData();
    const inputs = [...this.element.querySelectorAll("input, textarea")];
    const params = {};

    inputs.forEach((input) => {
      if (input.type !== "hidden") {
        formData.append(input.getAttribute("name"), input.value);
      } else {
        // Ignore _destroy fields or other nested fields
        if (input.getAttribute("name").indexOf("[") === -1) {
          params[input.getAttribute("name")] = input.value;
        }
      }
    });

    const contextParams = new URLSearchParams(params);

    return api
      .postForm(
        `/templates/${id}/preview?${contextParams.toString()}`,
        formData,
      )
      .then(({ data }) => {
        const { subject, body } = data;

        this.subjectTarget.textContent = subject;
        this.previewTarget.textContent = body;

        if (callback) {
          callback(data);
        }
      });
  }
}
