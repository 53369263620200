import { Controller } from "@hotwired/stimulus";
import cc from "../utils/c2a";

export default class extends Controller {
  static targets = ["search", "full", "addressComplete"];

  connect() {
    if (this.searchTarget) {
      const self = this;

      let dom = this.buildFields();
      dom.search = this.searchTarget;

      cc().attach(dom, {
        onResultSelected: function (c2a, elements, address) {
          try {
            if (self.hasAddressCompleteTarget) {
              self.addressCompleteTarget.value = self.condenseAddress(address);

              if (dom.postcode) {
                dom.postcode.value = address.postal_code;
              }
            }
          } catch (e) {
            console.log(e);
          }
        },
      });
    } else {
      console.log("Unable to access the searchTarget element");
    }
  }

  buildFields() {
    let fields = {};

    this.element.querySelectorAll("input[type=text]").forEach((e) => {
      const name = e.getAttribute("name");

      if (name) {
        if (name.endsWith("[line1]")) {
          fields.line_1 = e;
        }

        if (name.endsWith("[line2]")) {
          fields.line_2 = e;
        }

        if (name.endsWith("[postcode]")) {
          fields.postcode = e;
        }

        if (name.endsWith("[town]")) {
          fields.town = e;
        }

        if (name.endsWith("[company]")) {
          fields.company = e;
        }

        if (name.endsWith("[county]")) {
          fields.county = e;
        }

        if (name.endsWith("[country]")) {
          fields.country = e;
        }
      }
    });

    return fields;
  }

  condenseAddress(object) {
    return [object.line_1, object.line_2, object.line_3, object.locality]
      .filter((c) => c)
      .join(", ");
  }
}
