const cc = () => {
  if (window.c2a) {
    return window.c2a;
  } else {
    window.c2a = new clickToAddress({
      accessToken: "fd6c3-f5083-54a35-09000",
      defaultCountry: "gbr",
      enabledCountries: ["gbr"],
      countrySelector: false,
      domMode: "object",
    });

    return window.c2a;
  }
};

export default cc;
