export default (role) => ({
  role: role,
  published: !role.draft,
  salaryMode: role.salaryDisplayMode || "range",
  benefits: [],
  benefitIndex: 0,

  autoGenerateSlug: !role.slug || role.slug.length < 1,

  preview: false,
  requestedPreview: false,

  init() {
    if (role.benefits) {
      this.benefits = role.benefits.map((str) => {
        const id = this.benefitIndex++;
        return {
          id: id,
          benefit: str,
        };
      });
    }

    document.addEventListener("turbo:submit-end", (e) => {
      if (this.requestedPreview) {
        const formUrl = this.$refs.form.action;
        const segments = formUrl.split("/");

        this.$refs.iframe.src = `/jobs/${segments[segments.length - 1]}`;
        this.preview = true;
        this.requestedPreview = false;
      }
    });

    this.$nextTick(() => {
      this.generateSlug();
    });
  },

  publish(e) {
    if (
      confirm(
        "Are you sure you would like to publish this role? Once it has been published, the content, salary and details cannot be changed.",
      )
    ) {
      this.published = true;
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  },

  updatePreview() {
    this.requestedPreview = true;
    this.$refs.form.requestSubmit();
  },

  addBenefit() {
    this.benefits.push({
      id: this.benefitIndex++,
      benefit: "",
    });
  },

  removeBenefit(idx) {
    this.benefits = this.benefits.filter((b, i) => i !== idx);
  },

  generateSlug() {
    if (this.autoGenerateSlug && this.role.title) {
      let components = this.role.title;

      if (this.role.displayedLocation) {
        components += ` ${this.role.displayedLocation}`;
      }

      this.role.slug = components
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    }
  },

  generateMetaDescription(e) {
    if (e && e.target) {
      const doc = new DOMParser().parseFromString(
        e.target.value.replace("><", "> <"),
        "text/html",
      );
      const text = doc.documentElement.textContent;

      const metaDescription = document.querySelector(
        `textarea[name="job_role[meta_description]"]`,
      );

      if (metaDescription) {
        metaDescription.placeholder = text.substring(0, 200);
      }
    }
  },
});
