import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["currentYears", "pastYears", "currentMonths", "pastMonths"];

  currentYearsTargetConnected() {
    this.hideOrShowAdditional();

    this.currentYearsTarget.addEventListener("change", (e) => {
      this.hideOrShowAdditional();
    });

    this.currentMonthsTarget.addEventListener("change", (e) => {
      this.hideOrShowAdditional();
    });
  }

  hideOrShowAdditional() {
    const currentYears = parseInt(this.currentYearsTarget.value);
    const currentMonths = parseInt(this.currentMonthsTarget.value);

    if (isNaN(currentYears) || isNaN(currentMonths)) {
      this.toggle(false);
    }

    const totalMonths = currentYears * 12 + currentMonths;

    if (totalMonths >= 5 * 12) {
      this.toggle(false);
    } else {
      this.toggle(true);
    }
  }

  toggle(show) {
    this.element.querySelectorAll("h2").forEach((e) => {
      if (!e.innerText.startsWith("Current")) {
        e.style.display = show ? "block" : "none";
      }
    });

    this.element.querySelectorAll("button").forEach((e) => {
      if (e.innerText === "Add Address") {
        e.style.display = show ? "block" : "none";
      }
    });
  }
}
