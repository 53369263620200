import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="present-end-date"
export default class extends Controller {
  static targets = ["datepicker", "checkbox"];

  connect() {
    this.update(this.checkboxTarget.checked);
  }

  toggle(e) {
    this.update(e.target.checked);
  }

  update(present) {
    if (this.hasDatepickerTarget) {
      this.datepickerTarget.style.opacity = !present ? "1.0" : "0.0";
    }
  }
}
