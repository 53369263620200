const coerceBooleanToValue = (raw) => {
  if (raw === "1" || raw === "true") {
    return "1";
  }

  if (raw === "0" || raw === "false") {
    return "0";
  }

  return null;
};

export default (initial) => ({
  value: coerceBooleanToValue(initial),

  init() {},

  get isYes() {
    return this.value === "1";
  },

  get isNo() {
    return this.value === "0";
  },

  setYes() {
    this.value = "1";
  },

  setNo() {
    this.value = "0";
  },
});
